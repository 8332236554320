.anio {
  font-family: Arial, Helvetica, sans-serif;
}

.publicacion-encabezado-1 {
  font-family: "Times New Roman", Times, serif;
  color: rgb(57, 103, 195);
  transition: 10s;
}

.publicacion-encabezado-1:hover {
  text-decoration: underline rgb(57, 103, 195);
}

.publicacion-encabezado-2 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  color: rgb(109, 108, 109);
}

.publicacion-encabezado-3 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-style: italic;
}
