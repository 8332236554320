.img-carrusel{
    width: 100%;
    height: 47.5rem;
}
@media only screen and (min-width: 320px) and (max-width: 800px) {
    .img-carrusel{
        margin-top: 3rem;
        width: 100%;
        height: 17rem;
    }
}

/*------/ Intro Single /------*/

.intro-single-c {
    padding: 2rem 0 3rem;
}

.intro-single-c .title-single-box {
    padding: 1rem 0 1rem 2rem;
}

.intro-single-c .title-single-box {
    border-left: 3px solid #2eca6a;
}

.intro-single-c .title-single-box .title-single {
    font-weight: 600;
    font-size: 2.2rem;
}

